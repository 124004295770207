import React from 'react'
import PropTypes from 'prop-types'
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from 'react-share'

import FaLinkedinIn from '@meronex/icons/fa/FaLinkedinIn'
import FaFacebookF from '@meronex/icons/fa/FaFacebookF'
import FaTwitter from '@meronex/icons/fa/FaTwitter'

import FaWhatsapp from '@meronex/icons/fa/FaWhatsapp'

const Share = ({ socialConfig, tags }) => (
    <>
        <FacebookShareButton
            url={socialConfig.config.url}
            quote={socialConfig.config.title}
            hashtag={tags}
            className="soc-icon fb my-2"
        >
            <FaFacebookF className="is-facebook-icon" size="1.5rem" />
        </FacebookShareButton>
        <TwitterShareButton
            url={socialConfig.config.url}
            title={socialConfig.config.title}
            via={socialConfig.twitterHandle.split('@').join('')}
            hashtags={tags}
            className="soc-icon tw mx-2 my-2"
        >
            <FaTwitter className="is-twitter-icon" size="1.5rem" />
        </TwitterShareButton>
        <LinkedinShareButton
            url={socialConfig.config.url}
            title={socialConfig.config.title}
            summary={socialConfig.config.description}
            className="soc-icon lin mx-2 my-2"
        >
            <FaLinkedinIn className="is-linkedin-icon" size="1.5rem" />
        </LinkedinShareButton>
        <WhatsappShareButton
            url={socialConfig.config.url}
            title={socialConfig.config.title}
            className="soc-icon wh mx-2 my-2"
        >
            <FaWhatsapp className="is-whatsapp-icon" size="1.5rem" />
        </WhatsappShareButton>
    </>
)

Share.propTypes = {
    socialConfig: PropTypes.shape({
        twitterHandle: PropTypes.string.isRequired,
        config: PropTypes.shape({
            url: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
        }),
    }).isRequired,
    tags: PropTypes.arrayOf(PropTypes.string),
}
Share.defaultProps = {
    tags: [],
}

export default Share
